.btn {
    font-size: 16px;
    width: 64px;
    height: 64px;
}

.content {
    margin: 16px;
    padding: 16px;
    min-height: 280px;
    font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.nav-wrapper {
    display: flex;
    justify-content: space-between;
}