.form-control {
    position: relative;
    margin-bottom: 20px;
}

.label {
    position: absolute;
    top: 50%;
    left: 15px;
    font-size: 16px;
    font-weight: 500;
    padding: 0px 8px;
    pointer-events: none;
    background-color: #fff;
    color: rgb(144, 142, 142);
    transform: translateY(-50%);
    transition: all 0.3s ease-in-out;
}

.input {
    height: 30px;
    width: 340px;
    padding: 10px 20px 10px 20px;
    font-weight: 400;
    font-size: 16px;
    border: 1px solid #dadce0;
    border-radius: 4px;
    outline: none;
    transition: all 0.3s ease-in-out;
    @media (max-width: 400px) {
        height: 30px;
        width: 300px;
    }
}

.form-control input:focus {
    border: 2px solid #b07;
}

.form-control input:focus+.label,
.form-control input:valid+.label {
    top: 0px;
    color: rgb(67, 67, 67);
    font-size: 13px;
}

.icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    cursor: pointer;
}