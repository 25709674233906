.form-control {
    text-align: center;
    color: #fff;
    margin-top: 50px;
}

.btn {
    padding: 16px 36px;
    background-color: #b07;
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    border: none;
    border-radius: 9px;
    box-shadow: 0px 4px 5px 0px #999999;
}

.btn:hover {
    background-color: rgb(211, 130, 182);
}

.btn:active {
    box-shadow: 0px 0px 0px 0px;
}