.wrapper {
  display: flex;
  align-items: center;
}

.wrapper .icon {
  margin-right: 8px;
  color: #000000;
  font-weight: bold;
  float: left;
}