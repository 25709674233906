.wrapper {
  margin-top: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper .title {
  font-size: 14px;
  font-weight: bold;
  padding-right: 10px;
}

.wrapper .content {
  font-weight: 400;
  font-size: 14px;
}