.error {
    display: flex;
    padding: 10px 20px;
    background-color: #f1b4b4;
    border-radius: 7px;
    align-items: center;
    min-width: 300px;
}

.error .error-icon {
    padding-right: 20px;
    color: rgb(251, 77, 77);
}

.error .error-message {
    font-size: 12px;
    color: #fff;
    padding-left: 20px;
}