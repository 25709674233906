.wrapper {
    padding: 0px 0px 10px 0px;
    display: flex;
    justify-content: left;
    align-items: center;
    cursor: pointer;
}

.wrapper label {
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
}

.wrapper input {
    width: 14px;
    height: 14px;
    margin-right: 6px;
    border: 1px solid var(--border-color);
    cursor: pointer;
}