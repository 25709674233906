.wrapper {
    background: url('../../assets/background.jpg') no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content {
    background-color: #fff;
    width: 600px;
    height: 500px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media (max-width: 600px) {
        width: 100%;
        height: 100%;
    }
}

.auth-header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo {
    max-width: 78px;
    border: 1px solid #007d9a;
    border-radius: 10%;
}

.title {
    padding: 10px;
}

.auth-body {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.auth-footer {
    padding: 12px 13px;
    color: #666;
    font-size: 1.1rem;
    line-height: 1.6;
    text-align: center;
    max-width: 250px;
}

.auth-footer .action a {
    font-size: 13px;
    font-weight: 500;
    color: rgb(13, 132, 236);
    text-decoration-line: none;
}