.wrapper {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.icon {
  display: flex;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
  width: 45px;
}

.icon .count {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  background: #f62718;
  color: #ffffff;
  width: 14px;
  height: 14px;
  font-size: 12px;
  border-radius: 50%;
  left: 24px;
  bottom: 10px;
}

.icon:hover{
  color: #008cff;
}