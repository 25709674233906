.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wrapper .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  font-size: 60px;
}

.wrapper .body {
  margin-left: 10px;
}

.wrapper .body .title {
  font-weight: 600;
  font-size: 15px;
}

.wrapper .body .content {
  font-size: 12px;
}

.wrapper .btn{
  margin-right: 10px;
}